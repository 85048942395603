exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-creator-index-tsx": () => import("./../../../src/pages/creator/index.tsx" /* webpackChunkName: "component---src-pages-creator-index-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-game-balance-tsx": () => import("./../../../src/pages/game/balance.tsx" /* webpackChunkName: "component---src-pages-game-balance-tsx" */),
  "component---src-pages-game-food-personality-all-tsx": () => import("./../../../src/pages/game/food-personality/all.tsx" /* webpackChunkName: "component---src-pages-game-food-personality-all-tsx" */),
  "component---src-pages-game-food-personality-index-tsx": () => import("./../../../src/pages/game/food-personality/index.tsx" /* webpackChunkName: "component---src-pages-game-food-personality-index-tsx" */),
  "component---src-pages-game-food-personality-result-tsx": () => import("./../../../src/pages/game/food-personality/result.tsx" /* webpackChunkName: "component---src-pages-game-food-personality-result-tsx" */),
  "component---src-pages-game-muckjjang-tsx": () => import("./../../../src/pages/game/muckjjang.tsx" /* webpackChunkName: "component---src-pages-game-muckjjang-tsx" */),
  "component---src-pages-game-what-to-eat-tsx": () => import("./../../../src/pages/game/what-to-eat.tsx" /* webpackChunkName: "component---src-pages-game-what-to-eat-tsx" */),
  "component---src-pages-img-tsx": () => import("./../../../src/pages/img.tsx" /* webpackChunkName: "component---src-pages-img-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

